import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, CircularProgress, Menu,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Device from '../../../resources/images/box.svg';
import { formatNotificationTitle, formatTime } from '../../../common/util/formatter';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { useAdministrator } from '../../../common/util/permissions';
import customFetch from '../../../common/util/customFetch';

const LoggedInUserNotification = () => {
  const t = useTranslation();
  const devices = useSelector((state) => state.devices.items);
  const user = useSelector((state) => state.session.user);
  const userEvents = useSelector((state) => state.session.userEvents);
  const administrator = useAdministrator();
  const [anchorEl, setAnchorEl] = useState(null);
  const [events, setEvents] = useState([]);
  const [readEvents, setReadEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userEvents.length > 0) {
      const updatdEvents = userEvents.map((event) => ({
        ...event,
        read: readEvents.findIndex((read) => read === event.id) !== -1,
      }));
      setEvents(updatdEvents);
    }
  }, [userEvents, readEvents]);

  const hours12 = true;
  const formatType = (event) => formatNotificationTitle(t, {
    type: event.type,
    attributes: {
      alarms: event.attributes.alarm,
    },
  });

  const readUserEvent = async (eventId) => {
    const read = [...readEvents];
    read.push(eventId);
    setReadEvents(read);
    await customFetch('/api/permissions', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user.id, eventId }),
    });
  };

  const readAllUserEvents = async () => {
    const allRead = [];
    if (userEvents.length > 0) {
      const body = userEvents.map((event) => {
        allRead.push(event.id);
        return { userId: user.id, eventId: event.id };
      });
      setReadEvents(allRead);
      setLoading(true);
      await customFetch('/api/permissions/bulk', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotifications = () => {
    setAnchorEl(null);
    setTimeout(() => {
      navigate(administrator ? '/reports/event?viewAll=1' : '/settings/alerts?viewAll=1');
    }, 500);
  };
  return (
    <div>
      <Button
        className={`user-profile-btn ${events.length > 0 ? 'notification-icon' : ''}`}
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <svg className="notification-icon-svg" xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" fill="none">
          <path d="M18.513 9.25908V12.8111C18.5554 13.6966 18.7945 14.5615 19.213 15.3431L20.645 17.6861C20.8368 18.0022 20.9545 18.3576 20.9892 18.7257C21.024 19.0938 20.9749 19.465 20.8456 19.8114C20.7164 20.1578 20.5104 20.4705 20.243 20.7259C19.9757 20.9813 19.654 21.1728 19.302 21.2861C13.9293 23.0558 8.1307 23.0558 2.75801 21.2861C2.39282 21.1917 2.05573 21.0108 1.77528 20.7586C1.49482 20.5064 1.27925 20.1903 1.14681 19.8372C1.01438 19.484 0.96897 19.1041 1.01444 18.7297C1.0599 18.3553 1.1949 17.9973 1.40801 17.6861L2.84201 15.3421C3.2695 14.5635 3.51273 13.6973 3.55301 12.8101V9.25908C3.58511 7.29644 4.38729 5.42504 5.78657 4.04846C7.18585 2.67187 9.07011 1.90039 11.033 1.90039C12.9959 1.90039 14.8802 2.67187 16.2795 4.04846C17.6787 5.42504 18.4809 7.29644 18.513 9.25908Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M13.1471 2.21688C12.7872 2.11085 12.4193 2.03427 12.0471 1.98788C10.9997 1.84933 9.93507 1.92727 8.91907 2.21688C9.0862 1.79245 9.37723 1.42815 9.75427 1.1714C10.1313 0.914653 10.5769 0.777344 11.0331 0.777344C11.4892 0.777344 11.9348 0.914653 12.3119 1.1714C12.6889 1.42815 12.9799 1.79245 13.1471 2.21688Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.463 21.793C14.4603 22.7015 14.0982 23.572 13.4557 24.2144C12.8131 24.8567 11.9425 25.2186 11.034 25.221C10.1256 25.2189 9.25494 24.8576 8.61196 24.216C7.96971 23.5729 7.60804 22.7018 7.60596 21.793" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      </Button>
      <Menu
        className="notification-dropdown"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 280, vertical: -12 }}
        open={open}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className="notification-menu">
          <div className="notification-header">
            <span>Notifications</span>
            <Button className="close-btn" onClick={() => setAnchorEl(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                <path d="M15.8124 14.96L1.69153 2" stroke="#9CA0A5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.7693 2.03906L1.64844 14.9991" stroke="#9CA0A5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Button>
          </div>
          <div className="notification-body">
            <div className="body-inner-content">
              <div className="notification-list">
                {/* Notification per day Start */}
                {events.length < 1 && (
                  <div
                    className="center-with-flex no-notifications"
                  >
                    You don&apos;t have any notifications
                  </div>
                )}
                <div>
                  {events.map((event) => (
                    <>
                      <span className="day-label" key={event.id}>{formatTime(event.eventTime, 'seconds', hours12)}</span>
                      <div className="notification-box" key={event.id}>
                        <div className="notification-subject">
                          <Button onClick={() => readUserEvent(event.id)}>
                            <div className="device-detail">
                              <img src={Device} alt="device-name" />
                              <span>
                                {`${devices[event.deviceId]?.name} has new alert`}
                              </span>
                            </div>
                          </Button>
                          {!event.read && (
                            <span className="status" />
                          )}
                        </div>
                        <div className="notification-detail">
                          <p>{`${devices[event.deviceId]?.name} has new alert: • ${formatType(event)}`}</p>
                        </div>
                        {/* <div className="notification-detail">
                          <p>{formatTime(event.eventTime, 'seconds', hours12)}</p>
                        </div> */}
                      </div>
                    </>
                  ))}

                  {/* <div className="notification-box">
                    <div className="notification-subject">
                      <div className="device-detail">
                        <img src={Device} alt="device-name" />
                        <span>Eelink 02 has new alerts</span>
                      </div>
                      <span className="status" />
                    </div>
                    <div className="notification-detail">
                      <p>The battery of this device is running low, and the SOS feature is currently activated.</p>
                    </div>
                  </div> */}
                </div>
                {/* Notification per day End */}
                {/* Notification per day Start */}
                {/* <div>
                  <span className="day-label">Yesterday</span>
                  <div className="notification-box">
                    <div className="notification-subject">
                      <div className="device-detail">
                        <img src={Device} alt="device-name" />
                        <span>Eelink 02 has new alerts</span>
                      </div>
                      <span className="status" />
                    </div>
                    <div className="notification-detail">
                      <p>The battery of this device is running low, and the SOS feature is currently activated.</p>
                    </div>
                  </div>
                  <div className="notification-box">
                    <div className="notification-subject">
                      <div className="device-detail">
                        <img src={Device} alt="device-name" />
                        <span>Eelink 02 has new alerts</span>
                      </div>
                      <span className="status" />
                    </div>
                    <div className="notification-detail">
                      <p>The battery of this device is running low, and the SOS feature is currently activated.</p>
                    </div>
                  </div>
                </div> */}
                {/* Notification per day End */}
              </div>
            </div>
          </div>
          <div className="notification-footer">
            <div className="footer-inner-content">
              <Button className="mark-btn btn" disabled={loading} onClick={() => readAllUserEvents()}>
                {loading ? <CircularProgress /> : (
                  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 48096235">
                      <g id="Component 13 &#226;&#128;&#147; 119">
                        <path id="Path 49566" d="M4.63152 9.34351C4.5035 9.3435 4.37674 9.31824 4.2585 9.26917C4.14025 9.22011 4.03284 9.1482 3.94242 9.05758L0.263878 5.37903C0.0906619 5.19436 -0.00392549 4.94955 0.000124917 4.69639C0.00417533 4.44323 0.106546 4.20157 0.285582 4.02253C0.464617 3.8435 0.706277 3.74113 0.959439 3.73708C1.2126 3.73303 1.45741 3.82761 1.64208 4.00083L4.63152 6.99027L11.3138 0.307991C11.4032 0.212686 11.5108 0.136321 11.6303 0.0834337C11.7498 0.0305466 11.8787 0.00221512 12.0094 0.000124776C12.14 -0.00196557 12.2698 0.0222274 12.3909 0.071265C12.512 0.120303 12.622 0.193185 12.7144 0.285582C12.8068 0.377978 12.8797 0.488005 12.9287 0.609123C12.9778 0.730242 13.002 0.859982 12.9999 0.990634C12.9978 1.12129 12.9695 1.25019 12.9166 1.36967C12.8637 1.48916 12.7873 1.5968 12.692 1.68619L5.32063 9.05758C5.2302 9.1482 5.1228 9.22011 5.00455 9.26917C4.88631 9.31824 4.75954 9.3435 4.63152 9.34351Z" fill="#021021" />
                      </g>
                      <g id="Component 13 &#226;&#128;&#147; 120">
                        <path id="Path 49566_2" d="M4.63152 14.1521C4.5035 14.1521 4.37674 14.1268 4.2585 14.0778C4.14025 14.0287 4.03284 13.9568 3.94242 13.8662L0.263878 10.1876C0.0906619 10.003 -0.00392549 9.75815 0.000124917 9.50498C0.00417533 9.25182 0.106546 9.01016 0.285582 8.83113C0.464617 8.65209 0.706277 8.54972 0.959439 8.54567C1.2126 8.54162 1.45741 8.63621 1.64208 8.80942L4.63152 11.7989L11.3138 5.11658C11.4032 5.02128 11.5108 4.94491 11.6303 4.89203C11.7498 4.83914 11.8787 4.81081 12.0094 4.80872C12.14 4.80663 12.2698 4.83082 12.3909 4.87986C12.512 4.9289 12.622 5.00178 12.7144 5.09418C12.8068 5.18657 12.8797 5.2966 12.9287 5.41772C12.9778 5.53884 13.002 5.66858 12.9999 5.79923C12.9978 5.92988 12.9695 6.05878 12.9166 6.17827C12.8637 6.29776 12.7873 6.40539 12.692 6.49479L5.32063 13.8662C5.2302 13.9568 5.1228 14.0287 5.00455 14.0778C4.88631 14.1268 4.75954 14.1521 4.63152 14.1521Z" fill="#021021" />
                      </g>
                    </g>
                  </svg>
                )}
                <span>Marks all as read</span>
              </Button>
              <Button className="btn btn--contained view-btn" variant="contained" onClick={handleNotifications}>
                <span>View all</span>
              </Button>
            </div>
          </div>
        </div>
      </Menu>

    </div>
  );
};
export default LoggedInUserNotification;
