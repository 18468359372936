import { useCallback, useRef, useState } from 'react';

/**
 * @param {{
 *  initialValues: Record<string, any>,
 *  formDisabled: boolean,
 * }} options
 */

export default ({ initialValues, formDisabled }) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(
    initialValues
      ? Object.keys(initialValues).reduce((object, key) => {
        object[key] = '';
        return object;
      }, {})
      : {},
  );
  const nodes = useRef({});
  const internalInfo = useRef({});

  const CharacterLimits = {
    street1: 100,
    street2: 100,
    city: 50,
    state: 50,
    postalCode: 20,
    country: 50,
    userName: 50,
    userLastName: 50,
    userEmail: 100,
    phone: 15,
    userCompanyName: 50,
  };

  const keysDisplayName = {
    street1: 'Address',
    street2: 'Address 2',
    city: 'City',
    state: 'State',
    postalCode: 'Zip Code',
    country: 'Country',
    phone: 'Phone Number',
    userName: 'First Name',
    userLastName: 'Last Name',
    userEmail: 'Email',
    userCompanyName: 'Company Name',
  };

  const getFieldValueByEvent = useCallback((name, event) => {
    const { usesEvent, checkbox } = internalInfo.current[name];

    return usesEvent
      ? checkbox
        ? event.target.checked
        : event.target.value
      : event;
  }, []);

  const validateField = useCallback(
    (name, value) => {
      const info = internalInfo.current[name];
      const { validate } = info || {};
      const error = validate?.(value);
      setErrors((errs) => ({ ...errs, [name]: error || '' }));
    },
    [setErrors],
  );

  const updateField = useCallback(
    (name, value) => {
      if (typeof value === 'string') {
        value = value.trimStart();
      }
      setValues((i) => ({ ...i, [name]: value }));
      validateField(name, value);
    },
    [validateField],
  );

  const register = useCallback(
    (
      name,
      {
        validate,
        checkbox,
        usesEvent = true,
        changeFuncName = 'onChange',
        valueProp = 'value',
        fieldRef,
      } = {},
    ) => {
      internalInfo.current[name] = {
        validate,
        checkbox,
        usesEvent,
        changeFuncName,
        valueProp,
      };

      return {
        [checkbox ? 'checked' : valueProp]: values?.[name],
        ref: (node) => {
          nodes.current[name] = node;
          if (fieldRef) fieldRef.current = node;
        },
        [changeFuncName]: (event) => {
          const value = getFieldValueByEvent(name, event);
          // validateField(name, value); // ONLY VALIDATE ON BLUR
          setErrors((errs) => ({ ...errs, [name]: '' })); // REMOVE ERROR MESSAGE.
          setValues((i) => ({
            ...i,
            [name]: typeof value === 'string' ? value.trimStart() : value,
          }));
        },
        disabled: !values || formDisabled,
        // error: !!errors[name],
        // helperText: errors[name],
        onBlur: () => {
          console.log(values?.[name], values?.[name].length, CharacterLimits[name]);
          if (values?.[name] && values?.[name].length > CharacterLimits[name]) {
            setErrors((errs) => ({ ...errs, [name]: `${keysDisplayName[name]} is too long.` }));
          } else {
            validateField(name, values?.[name]);
          }
        },
      };
    },
    [values, formDisabled, errors, getFieldValueByEvent, validateField],
  );

  return {
    register,
    values,
    errors,
    nodes,
    setValues,
    setErrors,
    updateField,
    validateField,
    isFormValid:
      Object.keys(internalInfo.current).length <= 0 || !values
        ? false
        : Object.keys(values).every((key) => {
          const keyInfo = internalInfo.current[key];
          if (!keyInfo || !keyInfo.validate) {
            return true;
          }
          const error = keyInfo.validate(values?.[key]);
          return !error;
        }),
  };
};
