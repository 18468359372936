import React from 'react';

const CustomSelect = ({ title, placeholder, options, helperText, error, required, ...selectProps }) => (
  <>
    <div className="custom_select">
      <p className="custom_select__title">
        {title}
        {required && <span className="MuiFormLabel-asterisk"> *</span>}
      </p>
      <select required {...selectProps}>
        <option value="" disabled selected>{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
          <path d="M1 1L5.5 6.175L10 1" stroke="#A0B0D0" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
    {error && <p className="text-error">{helperText}</p>}
  </>
);

export default CustomSelect;
