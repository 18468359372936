import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider, List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';
import CreateIcon from '@mui/icons-material/Create';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import LocalShipping from '@mui/icons-material/LocalShipping';
// import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
// import TodayIcon from '@mui/icons-material/Today';
import PublishIcon from '@mui/icons-material/Publish';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useAdministrator,
  useDeviceReadonly, useRestriction, useSuperAdministratorCanAccessEverything, useSuperAdministratorCanAccessTwilioCommand,
} from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(true);

  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();
  const superAdmin = useAdministrator();
  const superAdministratorCanAccessTwilioCommand = useSuperAdministratorCanAccessTwilioCommand();
  const superAdministratorCanAccessEverything = useSuperAdministratorCanAccessEverything();
  const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <>
      <List className="admin-drawer">
        {/* <MenuItem */}
        {/*  title={t('sharedPreferences')} */}
        {/*  link="/settings/preferences" */}
        {/*  icon={<SettingsIcon />} */}
        {/*  selected={location.pathname === '/settings/preferences'} */}
        {/* /> */}
        {!readonly && (
          <>
            <MenuItem
              title={t('settingsUser')}
              link={`/settings/user/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/settings/user/${userId}`}
            />
            {superAdministratorCanAccessEverything && (
              <MenuItem
                title={t('sharedNotifications')}
                link="/settings/notifications"
                icon={<NotificationsIcon />}
                selected={location.pathname.startsWith('/settings/notification')}
              />
            )}

            <MenuItem
              title={t('sharedGeofences')}
              link="/geofences"
              icon={<CreateIcon />}
              selected={location.pathname.startsWith('/settings/geofence')}
            />
            {!deviceReadonly && (
              <MenuItem
                title={t('deviceTitle')}
                link="/settings/devices"
                icon={<SmartphoneIcon />}
                selected={location.pathname.startsWith('/settings/device')}
              />
            )}

            {!features.disableGroups && (
              <MenuItem
                title={t('settingsGroups')}
                link="/settings/groups"
                icon={<FolderIcon />}
                selected={location.pathname.startsWith('/settings/group')}
              />
            )}
            {/* {!features.disableDrivers && ( */}
            {/*  <MenuItem */}
            {/*    title={t('sharedDrivers')} */}
            {/*    link="/settings/drivers" */}
            {/*    icon={<PersonIcon />} */}
            {/*    selected={location.pathname.startsWith('/settings/driver')} */}
            {/*  /> */}
            {/* )} */}
            {/* {!features.disableCalendars && ( */}
            {/*  <MenuItem */}
            {/*    title={t('sharedCalendars')} */}
            {/*    link="/settings/calendars" */}
            {/*    icon={<TodayIcon />} */}
            {/*    selected={location.pathname.startsWith('/settings/calendar')} */}
            {/*  /> */}
            {/* )} */}
            {superAdministratorCanAccessEverything && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/settings/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith('/settings/attribute')}
              />
            )}
            {/* {!features.disableMaintenance && ( */}
            {/*  <MenuItem */}
            {/*    title={t('sharedMaintenance')} */}
            {/*    link="/settings/maintenances" */}
            {/*    icon={<BuildIcon />} */}
            {/*    selected={location.pathname.startsWith('/settings/maintenance')} */}
            {/*  /> */}
            {/* )} */}
            {superAdministratorCanAccessEverything && (
              <>
                <MenuItem
                  title={t('sharedSavedCommands')}
                  link="/settings/commands"
                  icon={<PublishIcon />}
                  selected={location.pathname.startsWith('/settings/command') && !location.pathname.startsWith('/settings/command-send')}
                />
                <MenuItem
                  title="Satellite"
                  link="/settings/server"
                  icon={<StorageIcon />}
                  selected={location.pathname === '/settings/server'}
                />
              </>
            )}
          </>
        )}
      </List>
      <Divider />
      {(superAdmin) && (
        <List className="admin-drawer">
          {/* {admin && ( */}
          {/*  <MenuItem */}
          {/*    title={t('settingsServer')} */}
          {/*    link="/settings/server" */}
          {/*    icon={<StorageIcon />} */}
          {/*    selected={location.pathname === '/settings/server'} */}
          {/*  /> */}
          {/* )} */}
          <MenuItem
            title={t('settingsUsers')}
            link="/settings/users"
            icon={<PeopleIcon />}
            selected={location.pathname.startsWith('/settings/user') && location.pathname !== `/settings/user/${userId}`}
          />
        </List>
      )}
      <List className="admin-drawer" style={{ paddingTop: '0' }}>
        {(superAdministratorCanAccessTwilioCommand || superAdministratorCanAccessEverything) && (
          <MenuItem
            title="Twilio Commands"
            link="/settings/twilio-command"
            icon={<PublishIcon />}
            selected={location.pathname.startsWith('/settings/twilio-command')}
          />
        )}
        <MenuItem
          title={t('settingsOrders')}
          link="/settings/all-orders"
          icon={<ReceiptLong />}
          selected={location.pathname === '/settings/all-orders'}
        />
        {/* <MenuItem
          title="Addresses"
          link="/settings/all-addresses"
          icon={<LocalShipping />}
          selected={location.pathname === '/settings/all-addresses'}
        /> */}
      </List>
      <Divider />
      {superAdmin && (
        <List className="admin-drawer">
          <Accordion
            expanded={expanded}
            className="admin-drawer-accordion"
          >
            <AccordionSummary
              onClick={handleExpansion}
              expandIcon={(
                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 1L5.5 5L1 1" stroke="#A0B0D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Frontend Settings
            </AccordionSummary>
            <AccordionDetails>
              <MenuItem
                title="Testimonials"
                link="/settings/testimonials"
                icon={(
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 8C17.79 8 16 6.21 16 4C16 3.27 16.21 2.59 16.56 2H7C4.24 2 2 4.23 2 6.98V12.96V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V7.44C21.41 7.79 20.73 8 20 8Z" fill="currentColor" />
                    <path d="M12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12Z" fill="white" />
                    <path d="M16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z" fill="white" />
                    <path d="M8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12Z" fill="white" />
                    <path d="M21.4851 0.8175L21.7651 1.38749C21.9051 1.66749 22.2551 1.9275 22.5651 1.9875L22.9451 2.0475C24.0851 2.2375 24.3551 3.0775 23.5351 3.9075L23.1851 4.25749C22.9551 4.49749 22.8251 4.95749 22.8951 5.27749L22.9451 5.4875C23.2551 6.8675 22.5251 7.39749 21.3251 6.67749L21.0651 6.52749C20.7551 6.34749 20.2551 6.34749 19.9451 6.52749L19.6851 6.67749C18.4751 7.40749 17.7451 6.8675 18.0651 5.4875L18.1151 5.27749C18.1851 4.95749 18.0551 4.49749 17.8251 4.25749L17.4751 3.9075C16.6551 3.0775 16.9251 2.2375 18.0651 2.0475L18.4451 1.9875C18.7451 1.9375 19.1051 1.66749 19.2451 1.38749L19.5251 0.8175C20.0651 -0.2725 20.9451 -0.2725 21.4851 0.8175Z" fill="currentColor" />
                  </svg>
                )}
                selected={location.pathname === '/settings/testimonials'}
              />
              {/* <MenuItem
                title="FAQs"
                link="/settings/faqs"
                icon={(
                  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5 23.5C17.8513 23.5 23 18.3513 23 12C23 5.64873 17.8513 0.5 11.5 0.5C5.14873 0.5 0 5.64873 0 12C0 18.3513 5.14873 23.5 11.5 23.5Z" fill="currentColor" />
                    <path d="M11.9726 14.3023C11.5011 14.3023 11.1101 13.9113 11.1101 13.4398V13.1984C11.1101 11.8644 12.0876 11.2089 12.4556 10.9559C12.8811 10.6684 13.0191 10.4729 13.0191 10.1739C13.0191 9.59888 12.5476 9.12734 11.9726 9.12734C11.3976 9.12734 10.9262 9.59888 10.9262 10.1739C10.9262 10.6454 10.5352 11.0364 10.0637 11.0364C9.59217 11.0364 9.20117 10.6454 9.20117 10.1739C9.20117 8.64438 10.4431 7.40234 11.9726 7.40234C13.5021 7.40234 14.7441 8.64438 14.7441 10.1739C14.7441 11.4849 13.7782 12.1404 13.4217 12.3819C12.9732 12.6809 12.8351 12.8764 12.8351 13.1984V13.4398C12.8351 13.9228 12.4441 14.3023 11.9726 14.3023Z" fill="white" />
                    <path d="M11.9719 17.1664C11.4889 17.1664 11.1094 16.7754 11.1094 16.3039C11.1094 15.8324 11.5004 15.4414 11.9719 15.4414C12.4434 15.4414 12.8344 15.8324 12.8344 16.3039C12.8344 16.7754 12.4549 17.1664 11.9719 17.1664Z" fill="white" />
                  </svg>
                )}
                selected={location.pathname === '/settings/faqs'}
              /> */}
            </AccordionDetails>
          </Accordion>
        </List>
      )}
    </>
  );
};

export default SettingsMenu;
