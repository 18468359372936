import React from 'react';
import {
  Button, Grid, IconButton, Modal,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import CircleSpinner from '../../../../CircleSpinner/CircleSpinner';
import CustomSelect from '../../../../homePage/footer/pages/CustomSelect';

const AddFAQ = ({ openFAQModal, handleClose, title }) => (
  <Modal
    open={openFAQModal}
    onClose={handleClose}
    classes={{ root: 'center-with-flex' }}
  >
    <div
      className="create-testimonial-modal"
    >
      <div className="modal-header">
        <p>
          {title}
          {' '}
          FAQ
        </p>
        <IconButton
          className="cross-btn"
          onClick={handleClose}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9991 13.96L1.03906 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.96 1.03906L1 13.9991" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </IconButton>
      </div>
      <div className="modal-body">
        <div className="inner-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="form">
                <TextField
                  label="Question"
                  placeholder="Enter Question"
                  className="form-textfield"
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-textarea">
                <p className="label">
                  <span className="label-tag">Answer</span>
                  <span className="info-required">*</span>
                </p>
                <div className="textarea">
                  <TextareaAutosize
                    required
                    label="Answer"
                    name="Review"
                    value=""
                    placeholder="Enter Answer"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <CustomSelect
                title="Status"
                required
                placeholder={
                  <div className="select-placeholder-text">Getting Started</div>
            }
                options={[
                  { value: '0', label: 'Getting Started' },
                  { value: '1', label: 'Dashboard' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <CustomSelect
                title="Status"
                required
                placeholder={
                  <div className="select-placeholder-text">Inactive</div>
            }
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                  { value: 'draft', label: 'Draft' },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className="form">
                <TextField
                  type="number"
                  label="Sort Order"
                  placeholder="1"
                  className="form-textfield"
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="modal-footer">
          {/* <CircleSpinner /> */}
          <Button
            className="btn btn--contained"
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);

export default AddFAQ;
