import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import customErrorMessages from '../util/customErrorMessages';

const AlertMessage = ({
  message = '',
  alertHeaderMessage = '',
  severity = 'success',
  onClose,
  show,
  vertical = 'top',
  horizontal = 'right',
  duration = 10000,
}) => (
  <Snackbar
    open={show}
    onClose={onClose}
    autoHideDuration={duration}
    anchorOrigin={{ vertical, horizontal }}
    key={vertical + horizontal}
    className="alert-msg"
  >
    <Alert
      elevation={6}
      severity={severity}
      variant="filled"
    >
      {(severity === 'success') && (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#D8F4D9" />
          <path d="M11 20L16.9929 26L29 14" stroke="#179B2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )}
      {(severity === 'warning') && (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#FFE9CD" />
          <path d="M19.5454 12V22.8182" stroke="#FF8F00" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="1.54545" cy="1.54545" r="1.54545" transform="matrix(1 0 0 -1 18 29)" fill="#FF8F00" />
        </svg>
      )}
      {(severity === 'error') && (
        <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.2491 32.2816H7.4016C1.76285 32.2816 -0.593403 28.2516 2.1366 23.3278L7.2066 14.1953L11.9841 5.61531C14.8766 0.399063 19.6216 0.399063 22.5141 5.61531L27.2916 14.2116L32.3616 23.3441C35.0916 28.2678 32.7191 32.2978 27.0966 32.2978H17.2491V32.2816Z" fill="#FFDFE3" stroke="#FFDFE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M17.249 12.1152L16.9998 22.0005" stroke="#F13737" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M17.2402 25.1152H17.2548H17.2402Z" fill="#F13737" />
          <path d="M17.2402 25.1152H17.2548" stroke="#F13737" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )}
      {(severity === 'info') && (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#E4F2FF" />
          <path d="M19.5454 29V18.1818" stroke="#3A70E2" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="19.5455" cy="13.5455" r="1.54545" fill="#3A70E2" />
        </svg>
      )}
      <div className="inner-content">
        {alertHeaderMessage && (
          <p className="header">{customErrorMessages(alertHeaderMessage)}</p>
        )}
        <span className="detail">{customErrorMessages(message)}</span>
      </div>
    </Alert>
  </Snackbar>
);

export default AlertMessage;
