import React from 'react';
import { Input } from '@mui/material';
import CustomSelect from '../../../../homePage/footer/pages/CustomSelect';

const SettingsSearch = () => (
  <div className="search-header">
    <div className="searchbar">
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 17.3349L13.2477 13.1194M15.3503 8.10122C15.3532 9.97355 14.5992 11.7738 13.2477 13.1226C12.5919 13.7801 11.8044 14.3033 10.9326 14.6608C10.0608 15.0182 9.12288 15.2024 8.17504 15.2024C7.22719 15.2024 6.28899 15.0182 5.41719 14.6608C4.5454 14.3033 3.75794 13.7801 3.10207 13.1226C1.75321 11.7722 1 9.97296 1 8.10122C1 6.22948 1.75321 4.43024 3.10207 3.07983C3.75794 2.42233 4.5454 1.89912 5.41719 1.54169C6.28899 1.18427 7.22719 1 8.17504 1C9.12288 1 10.0608 1.18427 10.9326 1.54169C11.8044 1.89912 12.5919 2.42233 13.2477 3.07983C14.5992 4.42862 15.3532 6.22889 15.3503 8.10122Z" stroke="#BDC9E2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <Input placeholder="Search" />
    </div>
    <div className="selection-box">
      <CustomSelect
        placeholder={
          <div className="select-placeholder-text">Filter by Status</div>
            }
        options={[
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' },
        ]}
      />
      <CustomSelect
        placeholder={
          <div className="select-placeholder-text">Filter by Category</div>
            }
        options={[
          { value: '0', label: 'Getting Started' },
          { value: '1', label: 'Dashboard' },
        ]}
      />
    </div>
  </div>
);

export default SettingsSearch;
