import React, { useState } from 'react';
import { Container } from '@mui/material';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PageLayout from '../../../common/components/PageLayout';
import SettingsMenu from '../../components/SettingsMenu';
import SettingsHeader from './FAQHeader/SettingsHeader';
import SettingsSearch from './FAQHeader/SettingsSearch';
import SettingsPagination from '../../../common/components/Pagination/SettingsPagination';
import FAQArray from './FAQArray';

const faqsArray = [
  {
    id: '1',
    question: 'Why is GPS better than Bluetooth?',
    answer: (
      <>
        GPS (Global Positioning System) is better than Bluetooth for location tracking and navigation over long distances, as it relies on satellite signals to provide accurate and global positioning information, whereas Bluetooth has a shorter range and is more suitable for local device-to-device communication.
      </>
    ),
    category: 'Getting Started',
    order: '1',
    status: 'Active',
  },
  {
    id: '2',
    question: 'How to hide device?',
    answer: (
      <>
        If you a trying to deter theft, it may be helpful to hide your PEEQ POM so it is not easily detected. For example, put your PEEQ POM amongst whatever you are shipping, in another box, or even inside something of value. Keep in mind the sun sensor does need to be exposed if you want an alert if the package is opened.
      </>
    ),
    category: 'Dashboard',
    order: '2',
    status: 'Draft',
  },
  {
    id: '3',
    question: 'What if my device shows offline?',
    answer: (
      <>
        POM will show offline if it got disconnected, turned off or if the battery died. You may see your device sleeping, if it is stationary, your POM will wake up every 6 hours to give you its location. This is done to help save battery life.
      </>
    ),
    category: 'Getting Started',
    order: '3',
    status: 'Inactive',
  },
  {
    id: '4',
    question: 'How do I get started?',
    answer: (
      <>
        Getting started is easy. If you&apos;re a business looking to place a bulk order, please contact our sales team at +1-888-231-7337 or email us at sales@takeapeeq.com . If you&apos;re a consumer looking to place a small order, simply select your POM(s), create an account and checkout. For both types of orders, your POM can be automatically added to your account and shipped to you. Once you receive your POM, turn it on, log back into your account, set your preferences and start tracking! There is also a helpful step-by-step walk-through when you login.
      </>
    ),
    category: 'Dashboard',
    order: '4',
    status: 'Active',
  },
];

const FAQPage = () => {
  const [addFAQ, setAddFAQ] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const { width } = useWindowDimensions();
  const responseiveWidth = 1025;
  return (
    <div>
      <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'Testimonials']} testimonialsPage>
        <Container className="testimonial-settings-container">
          <div className="admin-testimonial-page">
            <SettingsHeader addCategory={addCategory} setAddCategory={setAddCategory} addFAQ={addFAQ} setAddFAQ={setAddFAQ} handleClose={() => setAddFAQ(!addFAQ)} title="Create" />
            <div className="testimonial-body">
              <SettingsSearch />
              {width > responseiveWidth && (
              <div className="table-header">
                <p>Question</p>
                <p className="review">Answer</p>
                <p>Category</p>
                <p>Sort</p>
                <p>Status</p>
                <p className="hidden">Actions</p>
              </div>
              )}
              <div className="table-body">
                {faqsArray.map((faq) => <FAQArray key={faq.id} responseiveWidth={responseiveWidth} faq={faq} />)}
              </div>
            </div>
          </div>
          <SettingsPagination />
        </Container>
      </PageLayout>
    </div>
  );
};

export default FAQPage;
