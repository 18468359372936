import React from 'react';
import {
  Button, IconButton, Modal,
} from '@mui/material';
import { useCatch } from '../../../../reactHelper';
import customFetch from '../../../../common/util/customFetch';

const DeleteFAQModal = ({
  openDeleteModal, itemName, setDeleteModal, itemId, onResult, endpoint, setAlertMessage, setShowAlertMessage, setAlertHeaderMessage,
}) => {
  const handleCloseChild = () => {
    setDeleteModal(!openDeleteModal);
  };
  const handleRemove = useCatch(async () => {
    const message = (
      <span>
        Your testimonial named
        {' '}
        <span style={{ color: '#1A74E2' }}>{itemName}</span>
        {' '}
        has been updated deleted successfully.
      </span>
    );
    const alertHeaderMessage = 'Geofence Deleted successfully';
    const response = await customFetch(`/api/${endpoint}/${itemId}`, { method: 'DELETE' });
    if (response.ok) {
      onResult(true);
      setDeleteModal(!openDeleteModal);
      setAlertHeaderMessage(alertHeaderMessage);
      setAlertMessage(message);
      setShowAlertMessage(true);
    } else {
      throw Error(await response.text());
    }
  });
  return (
    <Modal
      open={openDeleteModal}
      onClose={handleCloseChild}
      classes={{ root: 'center-with-flex' }}
    >
      <div
        className="purchased-modal-content"
      >
        <IconButton
          className="cross-btn"
          onClick={handleCloseChild}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9991 13.96L1.03906 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.96 1.03906L1 13.9991" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </IconButton>
        <div className="animation center-with-flex">
          <svg width="92" height="99" viewBox="0 0 92 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M90.2824 24.7093C73.9499 23.0694 57.5234 22.2285 41.1438 22.2285C31.4149 22.2298 21.6919 22.7273 12.0124 23.7194L2 24.7153" fill="#C2DBFA" />
            <path d="M90.2824 24.7093C73.9499 23.0694 57.5234 22.2285 41.1438 22.2285C31.4149 22.2298 21.6919 22.7273 12.0124 23.7194L2 24.7153" stroke="#C2DBFA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.4531 16.7477L29.5312 10.2416C30.3205 5.52444 30.9094 2 39.1964 2H52.0419C60.3348 2 60.9712 5.72121 61.7075 10.2952L62.7852 16.7477" fill="#1A74E2" />
            <path d="M28.4531 16.7477L29.5312 10.2416C30.3205 5.52444 30.9094 2 39.1964 2H52.0419C60.3348 2 60.9712 5.72121 61.7075 10.2952L62.7852 16.7477" stroke="#1A74E2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M78.4245 24.0449L75.3577 80.1186C74.8362 89.088 74.4111 96.0604 61.2431 96.0604H30.9391C17.7712 96.0604 17.3346 89.088 16.8301 80.1186L13.7578 24.0449" fill="#C2DBFA" />
            <path d="M78.4245 24.0449L75.3577 80.1186C74.8362 89.088 74.4111 96.0604 61.2431 96.0604H30.9391C17.7712 96.0604 17.3346 89.088 16.8301 80.1186L13.7578 24.0449" stroke="#C2DBFA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M39.4796 38.7411H24.7827C23.5775 38.7411 22.5781 37.7417 22.5781 36.5366C22.5781 35.3314 23.5775 34.332 24.7827 34.332H39.4796C40.6848 34.332 41.6842 35.3314 41.6842 36.5366C41.6842 37.7417 40.6848 38.7411 39.4796 38.7411Z" fill="#1A74E2" />
            <path d="M51.2372 50.4989H24.7827C23.5775 50.4989 22.5781 49.4995 22.5781 48.2944C22.5781 47.0892 23.5775 46.0898 24.7827 46.0898H51.2372C52.4424 46.0898 53.4418 47.0892 53.4418 48.2944C53.4418 49.4995 52.4424 50.4989 51.2372 50.4989Z" fill="#1A74E2" />
            <path d="M69.6071 69.6055C66.1533 69.6055 62.9567 70.818 60.4215 72.8388C57.0412 75.521 54.9102 79.6729 54.9102 84.3024C54.9102 87.0581 55.6818 89.6668 57.0413 91.8714C59.5765 96.1335 64.2427 98.9994 69.6071 98.9994C73.3181 98.9994 76.6984 97.6399 79.2704 95.3252C80.4094 94.3699 81.4014 93.1941 82.173 91.8714C83.5324 89.6668 84.3041 87.0581 84.3041 84.3024C84.3041 76.1824 77.7272 69.6055 69.6071 69.6055ZM75.4858 90.1078C74.9347 90.6589 74.2367 90.916 73.5386 90.916C72.8405 90.916 72.1423 90.6589 71.5911 90.1078L69.6439 88.1603L67.6231 90.1811C67.072 90.7323 66.3738 90.9896 65.6757 90.9896C64.9776 90.9896 64.2796 90.7323 63.7284 90.1811C62.6629 89.1156 62.6629 87.352 63.7284 86.2864L65.7492 84.2657L63.8018 82.3184C62.7362 81.2529 62.7362 79.4893 63.8018 78.4237C64.8673 77.3582 66.6309 77.3582 67.6964 78.4237L69.6439 80.371L71.481 78.5339C72.5466 77.4683 74.3102 77.4683 75.3757 78.5339C76.4412 79.5994 76.4412 81.363 75.3757 82.4285L73.5386 84.2657L75.4858 86.2131C76.5514 87.2786 76.5514 89.0055 75.4858 90.1078Z" fill="#1A74E2" />
          </svg>
        </div>
        <h2>
          Are you sure you want to delete this FAQ?
        </h2>
        <div className="modal-footer">
          <Button
            className="btn btn--outlined"
            variant="contained"
            onClick={handleCloseChild}
          >
            No
          </Button>
          <Button
            className="btn btn--contained"
            variant="contained"
            onClick={handleRemove}
          >
            Yes
          </Button>
        </div>

      </div>
    </Modal>
  );
};

export default DeleteFAQModal;
